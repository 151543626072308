@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@tailwind forms;

.react-datepicker__input-container input {
    @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm
}

.react-datepicker-popper {
    @apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded
}

.react-datepicker-left {
    @apply absolute left-0 right-auto top-11 transform-none !important
}

.react-datepicker-right {
    @apply absolute right-0 left-auto top-11 transform-none !important
}

.react-datepicker__portal {
    @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded
}

.react-datepicker__month-container {
    @apply flex flex-col
}

.react-datepicker__month {
    @apply flex flex-col
}

.react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800
}

.react-datepicker__week {
    @apply flex justify-around
}

.react-datepicker__day-names {
    @apply flex justify-around text-gray-400 font-medium text-center text-xs
}

.react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
    @apply absolute top-2
}

.react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded
}

.react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-40 hover:bg-transparent
}

.react-datepicker__day--outside-month {
    @apply text-gray-300
}

.react-datepicker__day--in-range {
    @apply bg-gray-200
}

.react-datepicker__day--in-selecting-range {
    @apply bg-blue-200
}

.react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selected {
    @apply bg-blue-500 text-white
}

.react-datepicker__day--range-start {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

.react-datepicker__day--range-end {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

.multiselect-container {
    @apply mt-1 relative !important
}

.search-wrapper {
    min-height: 0px !important;
    @apply bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm !important;

}

.search-wrapper .searchBox {
    padding: 0px !important;
    @apply text-left cursor-default sm:text-sm focus:ring-0 ring-0 mt-0 !important;
    outline: none !important;
}

.chip {
    @apply bg-purple-500 mb-0 text-white !important
}

.multiSelectContainer ul {
    @apply border-none border-0 !important;
}

.optionListContainer {
    @apply absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm !important
}

.option {
    @apply text-gray-900 font-semibold !important

}

.option.highlight {
    @apply text-white bg-purple-500 !important;
}

.option:hover {
    @apply text-white bg-purple-500 !important;
}

.optionContainer {
    @apply rounded-none !important;
}

[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background: plum;
    vertical-align: middle;
    margin-right: 1em;
    color: white;
}

.react-select__control {
    @apply bg-white relative w-full border border-gray-300 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm !important;
}

.react-select__input-container input {
    @apply outline-none ring-0 !important;
}

.react-select__option--is-selected {
    @apply text-white bg-purple-500 !important;
}

.react-select__option--is-focused {
    @apply text-white bg-purple-500 cursor-pointer !important;
}

#authorize-loading .checkmark {
    margin: 0 auto;
    padding-top: 40px;
}

#authorize-loading .path {
    stroke-dasharray: 300;
    stroke-dashoffset: 0;
    -webkit-animation-name: load, spin;
    -webkit-animation-duration: 3s, 3s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-name: load, spin;
    animation-duration: 3s, 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50px 50px;
}

#authorize-loading .path-complete {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

#authorize-loading .check {
    stroke-dasharray: 110;
    stroke-dashoffset: -110;
    stroke-width: 0;
}

#authorize-loading .check-complete {
    -webkit-animation: check 1s ease-in forwards;
    animation: check 1s ease-in forwards;
    stroke-width: 15;
    stroke-dashoffset: 0;
}

#authorize-loading .fill {
    stroke-dasharray: 285;
    stroke-dashoffset: -257;
    -webkit-animation: spin-fill 3s cubic-bezier(0.700, 0.435, 0.120, 0.600) infinite forwards;
    animation: spin-fill 3s cubic-bezier(0.700, 0.465, 0.120, 0.600) infinite forwards;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50px 50px;
}

#authorize-loading .fill-complete {
    -webkit-animation: fill 1s ease-out forwards;
    animation: fill 1s ease-out forwards;
}

@-webkit-keyframes load {
    0% {
        stroke-dashoffset: 300;
        -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
    50% {
        stroke-dashoffset: 0;
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    100% {
        stroke-dashoffset: -300;
    }
}

@keyframes load {
    0% {
        stroke-dashoffset: 285;
        animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    }
    50% {
        stroke-dashoffset: 0;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    100% {
        stroke-dashoffset: -285;
    }
}

@-webkit-keyframes check {
    0% {
        stroke-dashoffset: -110;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes check {
    0% {
        stroke-dashoffset: -110;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin-fill {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(720deg);
    }
}

@keyframes spin-fill {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(720deg);
    }
}

@-webkit-keyframes fill {
    0% {
        stroke-dashoffset: 285;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    0% {
        stroke-dashoffset: 285;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

#authorize-loading .success {
    stroke: #22c55e;
    transition: stroke .6s;
}